module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#00458a","display":"minimal-ui","icon":"/Users/gerrithalfmann/workspace/home-office-experts/static/images/homeoffice-favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-5117380-11"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
